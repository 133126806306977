/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

@import "~swiper/scss";
@import "~swiper/scss/navigation";
@import "~@ionic/angular/css/ionic-swiper";

@font-face {
  font-family: "Inter";
  src: url("./assets/fonts/Inter-Regular.ttf");
}

@font-face {
  font-family: "Inter";
  src: url("./assets/fonts/Inter-Bold.ttf");
  font-weight: bold;
}

html {
  --ion-default-font: "Inter", "Roboto", "Helvetica Neue", sans-serif;
  font-size: 16px;
}

ion-header {
  overflow: hidden;
}

ion-title {
  font-weight: 600;
  font-size: medium;
}

ion-button {
  --background: #f16531;
  --background-activated: #f16531;
  --border-color: red;
  --border-radius: 22px;
  text-transform: none;
}

ion-toast {
  --background: #fc7907;
  --color: #ffffff;
}

.chip {
  display: inline;
  border-radius: 30px;
  text-transform: capitalize;
  padding: 1px 8px 1px 8px;
  font-weight: bold;
}

.popover-farms {
  .popover-content {
    width: 300px !important;
  }
}

.farm-selection {
  width: 300px;
}

.label-farm-selection {
  padding-left: 6px;
}

.problemsAlert {
  .alert-wrapper {
    background-color: white;
    width: 300px;
    font-size: 16px;

    .alert-checkbox-group {
      button {
        .alert-button-inner {
          .alert-checkbox-icon {
            width: 30px;
            height: 30px;

            &[aria-checked="true"] {
              background-color: #fc7907;
            }
            .alert-checkbox-inner {
              width: 9px;
              height: 17px;
            }
          }
          [aria-checked="true"].alert-checkbox-icon {
            background-color: red;
          }
        }
      }
    }
  }
}

.problems-modal {
  .modal-wrapper {
    border-radius: 24px;
    height: auto;
    width: calc(100% - 32px);
    margin: 0 16px;
    background-color: white;
    padding-bottom: 25px;
  }
  .modal-shadow {
    display: none;
  }

  .ion-page {
    position: relative;
    contain: content;
    background-color: white;
  }
}

.item-success-modal {
  .modal-wrapper {
    border-radius: 8px;
    height: auto;
    width: calc(100% - 32px);
    margin: 0 16px;
    background-color: white;
    padding: 54px 67px 65px;
  }
  .modal-shadow {
    display: none;
  }

  .ion-page {
    position: relative;
    contain: content;
    background-color: white;
  }
}

.push-modal {
  .modal-wrapper {
    padding-bottom: 0 !important;
  }
}

.swiper-button-next:after,
.swiper-button-prev:after {
  font-size: 25px !important;
}

//ion-item::part(native) {
//  background: transparent;
//}
