// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root,
html[data-theme="light"] {
  --ion-color-primary: #0086ff;
  --ion-color-primary-rgb: 0, 134, 255;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #0079e5;
  --ion-color-primary-tint: #2396fe;

  --ion-color-secondary: #fcd000;
  --ion-color-secondary-rgb: 252, 208, 0;
  --ion-color-secondary-contrast: #000000;
  --ion-color-secondary-contrast-rgb: 0, 0, 0;
  --ion-color-secondary-shade: #ebc200;
  --ion-color-secondary-tint: #ffda2e;

  --ion-color-tertiary: #a400e1;
  --ion-color-tertiary-rgb: 164, 0, 225;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #8b00bf;
  --ion-color-tertiary-tint: #b744e2;

  --ion-color-success: #08a93c;
  --ion-color-success-rgb: 8, 169, 60;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #008f2d;
  --ion-color-success-tint: #1bc250;

  --ion-color-warning: #fc7907;
  --ion-color-warning-rgb: 255, 199, 0;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e4b50a;
  --ion-color-warning-tint: #feda3dce;

  --ion-color-danger: #e12a45;
  --ion-color-danger-rgb: 225, 42, 69;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #c70e2a;
  --ion-color-danger-tint: #fa5a72;

  --ion-color-dark: #282b2f;
  --ion-color-dark-rgb: 40, 43, 47;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #232629;
  --ion-color-dark-tint: #3e4044;

  --ion-color-medium: #788089;
  --ion-color-medium-rgb: 120, 128, 137;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #6a7179;
  --ion-color-medium-tint: #868d95;

  --ion-color-light: #ececec;
  --ion-color-light-rgb: 236, 236, 236;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d0d0d0;
  --ion-color-light-tint: #eeeeee;

  --ion-background-color: #ffffff;
  --ion-background-color-rgb: 255, 255, 255;

  --ion-text-color: #2a2a2a;
  --ion-text-color-rgb: 42, 42, 42;

  --ion-color-step-950: #1e1e1e;
  --ion-color-step-900: #2a2a2a;
  --ion-color-step-850: #363636;
  --ion-color-step-800: #414141;
  --ion-color-step-750: #4d4d4d;
  --ion-color-step-700: #595959;
  --ion-color-step-650: #656565;
  --ion-color-step-600: #717171;
  --ion-color-step-550: #7d7d7d;
  --ion-color-step-500: #898989;
  --ion-color-step-450: #949494;
  --ion-color-step-400: #a0a0a0;
  --ion-color-step-350: #acacac;
  --ion-color-step-300: #b8b8b8;
  --ion-color-step-250: #c4c4c4;
  --ion-color-step-200: #d0d0d0;
  --ion-color-step-150: #dbdbdb;
  --ion-color-step-100: #e7e7e7;
  --ion-color-step-50: #f3f3f3;
}

// @media (prefers-color-scheme: dark) {
/*
   * Dark Colors
   * -------------------------------------------
   */

body[data-theme="dark"] {
  --ion-color-primary: #0086ff;
  --ion-color-primary-rgb: 0, 134, 255;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #0079e5;
  --ion-color-primary-tint: #2396fe;

  --ion-color-secondary: #fcd000;
  --ion-color-secondary-rgb: 252, 208, 0;
  --ion-color-secondary-contrast: #000000;
  --ion-color-secondary-contrast-rgb: 0, 0, 0;
  --ion-color-secondary-shade: #ebc200;
  --ion-color-secondary-tint: #ffda2e;

  --ion-color-tertiary: #a400e1;
  --ion-color-tertiary-rgb: 164, 0, 225;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #8b00bf;
  --ion-color-tertiary-tint: #b744e2;

  --ion-color-success: #08a93c;
  --ion-color-success-rgb: 8, 169, 60;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #008f2d;
  --ion-color-success-tint: #1bc250;

  --ion-color-warning: #ffc700;
  --ion-color-warning-rgb: 255, 199, 0;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e4b50a;
  --ion-color-warning-tint: #feda3dce;

  --ion-color-danger: #e12a45;
  --ion-color-danger-rgb: 225, 42, 69;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #c70e2a;
  --ion-color-danger-tint: #fa5a72;

  --ion-color-light: #282b2f;
  --ion-color-light-rgb: 40, 43, 47;
  --ion-color-light-contrast: #ffffff;
  --ion-color-light-contrast-rgb: 255, 255, 255;
  --ion-color-light-shade: #232629;
  --ion-color-light-tint: #3e4044;

  --ion-color-medium: #788089;
  --ion-color-medium-rgb: 120, 128, 137;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #6a7179;
  --ion-color-medium-tint: #868d95;

  --ion-color-dark: #ececec;
  --ion-color-dark-rgb: 236, 236, 236;
  --ion-color-dark-contrast: #000000;
  --ion-color-dark-contrast-rgb: 0, 0, 0;
  --ion-color-dark-shade: #d0d0d0;
  --ion-color-dark-tint: #eeeeee;
}

/*
   * iOS Dark Theme
   * -------------------------------------------
   */

.ios body[data-theme="dark"] {
  --ion-background-color: #222831;
  --ion-background-color-rgb: 42, 42, 42;

  --ion-text-color: #ffffff;
  --ion-text-color-rgb: 255, 255, 255;

  --ion-color-step-50: #0d0d0d;
  --ion-color-step-100: #1a1a1a;
  --ion-color-step-150: #262626;
  --ion-color-step-200: #333333;
  --ion-color-step-250: #404040;
  --ion-color-step-300: #4d4d4d;
  --ion-color-step-350: #595959;
  --ion-color-step-400: #666666;
  --ion-color-step-450: #737373;
  --ion-color-step-500: #808080;
  --ion-color-step-550: #8c8c8c;
  --ion-color-step-600: #999999;
  --ion-color-step-650: #a6a6a6;
  --ion-color-step-700: #b3b3b3;
  --ion-color-step-750: #bfbfbf;
  --ion-color-step-800: #cccccc;
  --ion-color-step-850: #d9d9d9;
  --ion-color-step-900: #e6e6e6;
  --ion-color-step-950: #f2f2f2;

  --ion-item-background: #000000;

  --ion-card-background: #1c1c1d;
}

.ios ion-modal {
  --ion-background-color: var(--ion-color-step-100);
  --ion-toolbar-background: var(--ion-color-step-150);
  --ion-toolbar-border-color: var(--ion-color-step-250);
}

/*
   * Material Design Dark Theme
   * -------------------------------------------
   */

.md body[data-theme="dark"] {
  --ion-background-color: #222528;
  --ion-background-color-rgb: 42, 42, 42;

  --ion-text-color: #eeeeee;
  --ion-text-color-rgb: 255, 255, 255;

  --ion-border-color: #222222;

  --ion-color-step-50: #222831;
  --ion-color-step-100: #2a2a2a;
  --ion-color-step-150: #363636;
  --ion-color-step-200: #414141;
  --ion-color-step-250: #4d4d4d;
  --ion-color-step-300: #595959;
  --ion-color-step-350: #656565;
  --ion-color-step-400: #717171;
  --ion-color-step-450: #7d7d7d;
  --ion-color-step-500: #898989;
  --ion-color-step-550: #949494;
  --ion-color-step-600: #a0a0a0;
  --ion-color-step-650: #acacac;
  --ion-color-step-700: #b8b8b8;
  --ion-color-step-750: #c4c4c4;
  --ion-color-step-800: #d0d0d0;
  --ion-color-step-850: #dbdbdb;
  --ion-color-step-900: #e7e7e7;
  --ion-color-step-950: #f3f3f3;

  --ion-item-background: #222528;

  --ion-toolbar-background: #222528;

  --ion-tab-bar-background: #222528;

  --ion-card-background: #363636;
}
// }
